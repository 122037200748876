import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyGallery = lazy(
  () => import("./gallery-CF7GEtr9.js").then((module) => ({
    default: module.Gallery
  }))
);
function Gallery({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyGallery, { ...props });
}
const useGalleryContent = () => {
  return useMemo(
    () => ({
      gallery: {
        component: Gallery
      }
    }),
    []
  );
};
export {
  LazyGallery as Gallery,
  useGalleryContent
};
